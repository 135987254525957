import { render, staticRenderFns } from "./Organs.html?vue&type=template&id=444e100a&external"
import script from "./Organs.js?vue&type=script&lang=js&external"
export * from "./Organs.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports