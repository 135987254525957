import { render, staticRenderFns } from "./TestTypes.html?vue&type=template&id=6741bbe8&external"
import script from "./TestTypes.js?vue&type=script&lang=js&external"
export * from "./TestTypes.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports