import Swal from "sweetalert2";
import moment from "moment";
//import { excelParser } from "./excel-parser";
import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: "AddUser",
    mixins: [HelperMixin],
    async created() {

        await this.CheckLoginStatus();
        await this.GetAllDiseases(2);
        await this.GetAllCities();
        await this.GetAllUsers();
        await this.GetAllFacilities();
        this.GetInfo();




        //Add new 
        await this.GetAllNationalities();
        await this.GetAllGetAllCountries();
        await this.GetAllOccupations();
        await this.GetAllParasiteTypesInfo();
        await this.GetAllSpecializations();
        await this.GetAllFacilitiesForAllMunicipalities();
        await this.GetAllLaboratories();
        await this.GetAllSymptoms();




        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        this.dateNow = mm + "/" + dd + "/" + yyyy;


    },

    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("MMMM Do YYYY");
        },

        momentTime: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("DD/MM/YYYY");
        },

        momentYear: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("YYYY");
        },

    },


    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,


            Info: [],
            Requests: [],
            ApplicationsRequestsName: [],



            ById: '',
            CitiesId: '',
            MunicipalitiesId: '',
            FacilitiesId: '',
            DiseasesId: '',
            ByDate: '',

            //Info
            SelectedItem: '',


            activeName: '1',















            //Add Info

            dialogImageUrl: '',
            dialogVisible: false,
            activeNames: "",

            ApplicationsContactsList: [],

            ruleForm: {
                Id: 0,
                RequestsId: 0,

                DiseasesId: '',
                MunicipalitiesId: '',
                CityId: '',
                FacilitiesId: '',

                NotificationOn: '',
                NotificationBy: '',
                OnsetDate: '',
                PatientStatus: '',

                //OtherDetatils: '',

                FirstName: '',
                MiddleName: '',
                GrandfatherName: '',
                LastName: '',

                NationalitieId: '',
                Nid: '',
                Passport: '',
                Phone: '',

                DateOfBirth: '',
                MaritalStatus: '',
                Gender: '',
                Address: '',

                OccupationId: '',
                Workplace: '',
                OldWorkplace: '',
                SchoolName: '',

                IsResident: '',
                BloodType: '',
                DiedOn: '',

                PatientsNotes: '',
                Notes: '',


                Attachments: [],
                ApplicationsTravels: [],
                ApplicationsDiseasesHistory: [],
                ApplicationsBlodTransfusion: [],
                ApplicationsHospitalizations: [],
                ApplicationsInfected: [],
                ApplicationsContacts: [],
                ApplicationsLab: [],
                ApplicationsSymptoms: [],
                ApplicationsOrgans: [],
                ApplicationsAnimalsContacts: [],



            },
            rules: {

                DiseasesId: this.$helper.RequiredInput('المرض'),
                CityId: this.$helper.Required(),
                FacilitiesId: this.$helper.Required(),
                MunicipalitiesId: this.$helper.Required(),

                NotificationOn: this.$helper.Required(),
                NotificationBy: this.$helper.Required(),
                OnsetDate: this.$helper.Required(),
                PatientStatus: this.$helper.Required(),

                //OtherDetatils: this.$helper.Required(),

                FirstName: this.$helper.DynamicArabicEnterRequired('الاسم الاول'),
                MiddleName: this.$helper.DynamicArabicEnterRequired('إسم الاب'),
                GrandfatherName: this.$helper.DynamicArabicEnterRequired('إسم الجد'),
                LastName: this.$helper.DynamicArabicEnterRequired(' اللقب'),

                NationalitieId: this.$helper.RequiredInput('الجنسية'),
                Nid: this.$helper.NID(),
                Passport: this.$helper.RequiredInput('رقم جواز السفر او رقم الهوية'),
                Phone: [
                    {
                        required: true,
                        message: "الرجاء إدخال رقم الهاتف",
                        trigger: "blur",
                    },
                    {
                        min: 9,
                        max: 13,
                        message: "يجب ان يكون طول رقم الهاتف 9 ارقام علي الاقل",
                        trigger: "blur",
                    },
                    { required: true, pattern: /^[0-9]*$/, message: 'الرجاء إدخال ارقام فقط', trigger: 'blur' }
                ],

                DateOfBirth: this.$helper.RequiredInput('تاريخ الميلاد'),
                MaritalStatus: this.$helper.RequiredInput('الحالة الاجتماعية '),
                Gender: this.$helper.RequiredInput('الجنس'),

                OccupationId: this.$helper.Required(),
                Workplace: this.$helper.RequiredInput('مكان العمل'),

                IsResident: this.$helper.Required(),
                BloodType: this.$helper.RequiredInput(' فصيلة الدم '),


            },



            ApplicationsTravel: {
                Id: 0,
                TravelingToId: '',
                TravelingTo: '',
                TravelingOn: '',
                ReturningFromId: '',
                ReturningFrom: '',
                ReturningOn: '',
                Notes: '',
                TreatmentsBefore: '',
                TreatmentsAfter: '',
            },

            ApplicationsTravelrules: {
                TravelingToId: this.$helper.Required(),
                TravelingOn: this.$helper.Required(),
                ReturningFromId: this.$helper.Required(),
                ReturningOn: this.$helper.Required(),
            },



            ApplicationsDiseasesHistory: {
                Id: 0,
                DiseasesId: '',
                Diseases: '',
                ParasiteTypeId: '',
                ParasiteType: '',
                InjuredOn: '',
                InjuredDescriptions: '',
                HealedOn: '',
                HealedDescriptions: '',
                Treatments: '',
            },

            ApplicationsDiseasesHistoryrules: {
                DiseasesId: this.$helper.Required(),
                ParasiteTypeId: this.$helper.Required(),
                InjuredOn: this.$helper.Required(),
                HealedOn: this.$helper.Required(),
            },



            ApplicationsBlodTransfusion: {
                Id: 0,
                TransfusionOn: '',
                TransfusionBy: '',
            },

            ApplicationsBlodTransfusionrules: {
                TransfusionOn: this.$helper.Required(),
                TransfusionBy: this.$helper.Required(),
            },


            ApplicationsHospitalizations: {
                Id: 0,
                FacilitiesId: '',
                Facilities: '',
                EntryDate: '',
                OutDate: '',
                FileNumber: '',
                Surgery: '',
                SurgeryOn: '',
                SurgeryDoctorName: '',
                SurgeryDoctorSpecializationsId: '',
                SurgeryDoctorSpecializations: '',
                SurgeryDescriptions: '',
                PatietnStatus: '',
                Notes: '',
            },

            ApplicationsHospitalizationsrules: {
                FacilitiesId: this.$helper.Required(),
                EntryDate: this.$helper.Required(),
            },



            ApplicationsInfected: {
                Id: 0,
                Relations: '',
                Name: '',
                Phone: '',
                Address: '',
                Age: '',
                VaccinationStatus: '',
                Prosegers: '',
                Notes: '',
            },

            ApplicationsInfectedrules: {
                Relations: this.$helper.Required(),
                Name: this.$helper.Required(),
                Phone: [
                    {
                        required: true,
                        message: "الرجاء إدخال رقم الهاتف",
                        trigger: "blur",
                    },
                    {
                        min: 9,
                        max: 13,
                        message: "يجب ان يكون طول رقم الهاتف 9 ارقام علي الاقل",
                        trigger: "blur",
                    },
                    { required: true, pattern: /^[0-9]*$/, message: 'الرجاء إدخال ارقام فقط', trigger: 'blur' }
                ],
                VaccinationStatus: this.$helper.Required(),
                Prosegers: this.$helper.Required(),
            },


            ApplicationsContacts: {
                Id: 0,
                Relations: '',
                Name: '',
                Phone: '',
                Address: '',
                Age: '',
                VaccinationStatus: '',
                Prosegers: '',
                ContactOn: '',
                ContactAddress: '',
                Notes: '',
            },

            ApplicationsContactsrules: {
                Relations: this.$helper.Required(),
                Name: this.$helper.Required(),
                Phone: [
                    {
                        required: true,
                        message: "الرجاء إدخال رقم الهاتف",
                        trigger: "blur",
                    },
                    {
                        min: 9,
                        max: 13,
                        message: "يجب ان يكون طول رقم الهاتف 9 ارقام علي الاقل",
                        trigger: "blur",
                    },
                    { required: true, pattern: /^[0-9]*$/, message: 'الرجاء إدخال ارقام فقط', trigger: 'blur' }
                ],
                VaccinationStatus: this.$helper.Required(),
                ContactOn: this.$helper.Required(),
                ContactAddress: this.$helper.Required(),
            },



            ApplicationsLab: {
                Id: 0,
                LaboratoriesId: '',
                Laboratories: '',
                ParasiteTypeId: '',
                ParasiteType: '',
                TestTypesId: '',
                TestTypes: '',
                SampleTypeId: '',
                SampleType: '',
                TokinDate: '',
                SendToLabOn: '',
                DoctorName: '',
                DoctorPhone: '',
                DoctorNotes: '',
                Result: '',
                ResutlDescriptions: '',
                //ActionDate: '',
                Notes: '',
            },

            ApplicationsLabrules: {
                LaboratoriesId: this.$helper.Required(),
                ParasiteTypeId: this.$helper.Required(),
                TestTypesId: this.$helper.Required(),
                SampleTypeId: this.$helper.Required(),
                //SampleTypeId: this.$helper.SampleTypeId(),
                DoctorName: this.$helper.Required(),
                DoctorPhone: this.$helper.Required(),
            },


            ApplicationsSymptoms: {
                Id: 0,
                SymptomsId: '',
                Symptoms: '',
                Notes: '',
            },

            ApplicationsSymptomsrules: {
                SymptomsId: this.$helper.Required(),
            },


            ApplicationsOrgans: {
                Id: 0,
                OrgansId: '',
                Organs: '',
                Notes: '',
            },

            ApplicationsOrgansrules: {
                OrgansId: this.$helper.Required(),
            },

            ApplicationsAnimalsContacts: {
                Id: 0,
                AnimalTypesId: '',
                AnimalTypes: '',
                ContactWay: '',
                ContactOn: '',
                ContactAddress: '',
                Notes: '',
            },

            ApplicationsAnimalsContactsrules: {
                AnimalTypesId: this.$helper.Required(),
                ContactWay: this.$helper.Required(),
                ContactOn: this.$helper.Required(),
            },



        };
    },


    methods: {

        async GetMunicipalitiesInfo() {
            this.Municipalities = [];
            await this.GetMunicipalities(this.CitiesId);
            this.GetInfo();
        },

        async GetFacilitiesInfo() {
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetFacilities(this.MunicipalitiesId);
            this.GetInfo();
        },



        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            if (this.byDate)
                this.byDate = this.ChangeDate(this.byDate);

            this.$blockUI.Start();
            this.$http
                .GetApplicationsRequests(this.pageNo, this.pageSize, this.CitiesId, this.Municipalitiesid, this.FacilitiesId, this.DiseasesId, this.ById, this.ByDate)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        FilterByNumber() {
            this.Requests = [];
            let code = "";
            if (document.getElementById('selectInputPass') != null) {

                if (document.getElementById('selectInputPass').value == null || document.getElementById('selectInputPass').value == '')
                    return;

                code = document.getElementById('selectInputPass').value;
            }
            if (code.length <= 1)
                return;

            this.$blockUI.Start();
            this.$http.GetRequestsById(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Requests = response.data.info;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    console.error(err);
                });
        },

        Refresh() {
            this.CitiesId = '';
            this.MunicipalitiesId = '';
            this.FacilitiesId = '';
            this.DiseasesId = '';
            this.ById = '';
            this.ByDate = '';
            this.GetInfo();
        },

        Delete(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية الحذف ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteJobs(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },









        //Info
        ViewInfo(item) {
            this.SelectedItem = item;
            this.ruleForm.DiseasesId = this.SelectedItem.diseasesId;
            this.ChangeDiseases();
            this.state = 1;
            this.GetApplicationsRequest();
        },


        GetApplicationsRequest() {

            this.$blockUI.Start();
            this.$http
                .GetApplicationsRequestsName(this.SelectedItem.id)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.ApplicationsRequestsName = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },





































        //*************************************************************Add Info********************************************************************




        async Add_GetMunicipalitiesInfo() {
            this.ruleForm.MunicipalitiesId = '';
            this.Municipalities = [];
            this.ruleForm.FacilitiesId = '';
            this.Facilities = [];

            await this.GetMunicipalities(this.ruleForm.CityId);
        },

        async Add_GetFacilitiesInfo() {
            this.ruleForm.FacilitiesId = '';
            this.Facilities = [];
            await this.GetFacilities(this.ruleForm.MunicipalitiesId);
        },


        ChangeDiseases() {
            this.GetTestTypesInfo();
            this.GetTreatmentsTypesInfo();
            this.GetAllSampleTypesInfo();
            this.GetAllSymptomsInfo();
            this.GetAllOrgansInfo();
            this.GetAllAnimalTypesInfo();
        },

        async GetTestTypesInfo() {
            this.ApplicationsLab.TestTypesId = '',
                await this.GetTestTypes(this.ruleForm.DiseasesId)
        },

        async GetAllSampleTypesInfo() {
            this.ApplicationsLab.SampleTypeId = '',
                await this.GetAllSampleTypes(this.ruleForm.DiseasesId)
        },

        async GetTreatmentsTypesInfo() {
            await this.GetTreatmentsTypes(this.ruleForm.DiseasesId)
        },

        async GetAllSymptomsInfo() {
            await this.GetAllSymptoms(this.ruleForm.DiseasesId)
        },

        async GetAllOrgansInfo() {
            await this.GetAllOrgans(this.ruleForm.DiseasesId)
        },

        async GetAllAnimalTypesInfo() {
            await this.GetAllAnimalTypes(this.ruleForm.DiseasesId)
        },




        submitTravelForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let Obj = {
                        Id: Number(0),
                        TravelingToId: this.ApplicationsTravel.TravelingToId,
                        TravelingTo: this.Countries.find(item => item.id === this.ApplicationsTravel.TravelingToId).name,
                        TravelingOn: this.ApplicationsTravel.TravelingOn,
                        ReturningFromId: this.ApplicationsTravel.ReturningFromId,
                        ReturningFrom: this.Countries.find(item => item.id === this.ApplicationsTravel.ReturningFromId).name,
                        ReturningOn: this.ApplicationsTravel.ReturningOn,
                        Notes: this.ApplicationsTravel.Notes,
                        TreatmentsBefore: this.ApplicationsTravel.TreatmentsBefore,
                        TreatmentsAfter: this.ApplicationsTravel.TreatmentsAfter,
                    }
                    this.ruleForm.ApplicationsTravels.push(Obj);
                    this.resetForm(formName);
                    this.ApplicationsTravel.Notes = "";
                    this.ApplicationsTravel.TreatmentsBefore = "";
                    this.ApplicationsTravel.TreatmentsAfter = "";

                } else {
                    return false;
                }
            });
        },

        DeleteTravel(Listitem) {

            let indexToRemove = this.ruleForm.ApplicationsTravels.findIndex(item => item === Listitem);

            if (indexToRemove !== -1) {
                this.ruleForm.ApplicationsTravels.splice(indexToRemove, 1);
            }
        },





        submitDiseasesHistoryForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let Obj = {
                        Id: Number(0),
                        DiseasesId: this.ApplicationsDiseasesHistory.DiseasesId,
                        Diseases: this.Diseases.find(item => item.id === this.ApplicationsDiseasesHistory.DiseasesId).name,
                        ParasiteTypeId: this.ApplicationsDiseasesHistory.ParasiteTypeId,
                        ParasiteType: this.ParasiteTypes.find(item => item.id === this.ApplicationsDiseasesHistory.ParasiteTypeId).name,
                        InjuredOn: this.ApplicationsDiseasesHistory.InjuredOn,
                        InjuredDescriptions: this.ApplicationsDiseasesHistory.InjuredDescriptions,
                        HealedOn: this.ApplicationsDiseasesHistory.HealedOn,
                        HealedDescriptions: this.ApplicationsDiseasesHistory.HealedDescriptions,
                        Treatments: this.ApplicationsDiseasesHistory.Treatments,
                    }
                    this.ruleForm.ApplicationsDiseasesHistory.push(Obj);
                    this.resetForm(formName);

                    this.ApplicationsDiseasesHistory.InjuredDescriptions = "";
                    this.ApplicationsDiseasesHistory.HealedDescriptions = "";
                    this.ApplicationsDiseasesHistory.Treatments = "";

                } else {
                    return false;
                }
            });
        },

        DeleteDiseasesHistory(Listitem) {

            let indexToRemove = this.ruleForm.ApplicationsDiseasesHistory.findIndex(item => item === Listitem);

            if (indexToRemove !== -1) {
                this.ruleForm.ApplicationsDiseasesHistory.splice(indexToRemove, 1);
            }
        },






        submitBlodTransfusionForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let Obj = {
                        Id: Number(0),
                        TransfusionOn: this.ApplicationsBlodTransfusion.TransfusionOn,
                        TransfusionBy: this.ApplicationsBlodTransfusion.TransfusionBy,
                    }
                    this.ruleForm.ApplicationsBlodTransfusion.push(Obj);
                    this.resetForm(formName);

                } else {
                    return false;
                }
            });
        },

        DeleteBlodTransfusion(Listitem) {

            let indexToRemove = this.ruleForm.ApplicationsBlodTransfusion.findIndex(item => item === Listitem);

            if (indexToRemove !== -1) {
                this.ruleForm.ApplicationsBlodTransfusion.splice(indexToRemove, 1);
            }
        },




        submitHospitalizationsForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let Obj = {
                        Id: Number(0),
                        FacilitiesId: this.ApplicationsHospitalizations.FacilitiesId,
                        Facilities: this.ForAllMunicipalities.find(item => item.id === this.ApplicationsHospitalizations.FacilitiesId).name,
                        EntryDate: this.ApplicationsHospitalizations.EntryDate,
                        OutDate: this.ApplicationsHospitalizations.OutDate,
                        FileNumber: this.ApplicationsHospitalizations.FileNumber,
                        Surgery: this.ApplicationsHospitalizations.Surgery,
                        SurgeryOn: this.ApplicationsHospitalizations.SurgeryOn,
                        SurgeryDoctorName: this.ApplicationsHospitalizations.SurgeryDoctorName,
                        SurgeryDoctorSpecializationsId: this.ApplicationsHospitalizations.SurgeryDoctorSpecializationsId,
                        SurgeryDoctorSpecializations: this.Specializations.find(item => item.id === this.ApplicationsHospitalizations.SurgeryDoctorSpecializationsId).name,
                        SurgeryDescriptions: this.ApplicationsHospitalizations.SurgeryDescriptions,
                        PatietnStatus: this.ApplicationsHospitalizations.PatietnStatus,
                        Notes: this.ApplicationsHospitalizations.Notes,
                    }
                    this.ruleForm.ApplicationsHospitalizations.push(Obj);
                    this.resetForm(formName);
                    this.ApplicationsHospitalizations.FileNumber = "";
                    this.ApplicationsHospitalizations.OutDate = "";
                    this.ApplicationsHospitalizations.Surgery = "";
                    this.ApplicationsHospitalizations.SurgeryOn = "";
                    this.ApplicationsHospitalizations.SurgeryDoctorName = "";
                    this.ApplicationsHospitalizations.SurgeryDoctorSpecializationsId = "";
                    this.ApplicationsHospitalizations.SurgeryDescriptions = "";
                    this.ApplicationsHospitalizations.PatietnStatus = "";
                    this.ApplicationsHospitalizations.Notes = "";

                } else {
                    return false;
                }
            });
        },

        DeleteHospitalizations(Listitem) {

            let indexToRemove = this.ruleForm.ApplicationsHospitalizations.findIndex(item => item === Listitem);

            if (indexToRemove !== -1) {
                this.ruleForm.ApplicationsHospitalizations.splice(indexToRemove, 1);
            }
        },





        submitApplicationsInfectedForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let Obj = {
                        Id: Number(0),
                        Relations: this.ApplicationsInfected.Relations,
                        Name: this.ApplicationsInfected.Name,
                        Phone: this.ApplicationsInfected.Phone,
                        Address: this.ApplicationsInfected.Address,
                        Age: Number(this.ApplicationsInfected.Age),
                        VaccinationStatus: this.ApplicationsInfected.VaccinationStatus,
                        Prosegers: this.ApplicationsInfected.Prosegers,
                        Notes: this.ApplicationsInfected.Notes,
                    }
                    this.ruleForm.ApplicationsInfected.push(Obj);
                    this.resetForm(formName);
                    this.ApplicationsInfected.Age = "";
                    this.ApplicationsInfected.Phone = "";
                    this.ApplicationsInfected.Address = "";
                    this.ApplicationsInfected.Notes = "";

                } else {
                    return false;
                }
            });
        },

        DeleteApplicationsInfected(Listitem) {

            let indexToRemove = this.ruleForm.ApplicationsInfected.findIndex(item => item === Listitem);

            if (indexToRemove !== -1) {
                this.ruleForm.ApplicationsInfected.splice(indexToRemove, 1);
            }
        },



        submitApplicationsContactsForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let Obj = {
                        Id: Number(0),
                        Relations: this.ApplicationsContacts.Relations,
                        Name: this.ApplicationsContacts.Name,
                        Phone: this.ApplicationsContacts.Phone,
                        Address: this.ApplicationsContacts.Address,
                        Age: Number(this.ApplicationsContacts.Age),
                        VaccinationStatus: this.ApplicationsContacts.VaccinationStatus,
                        Prosegers: this.ApplicationsContacts.Prosegers,
                        ContactOn: this.ApplicationsContacts.ContactOn,
                        ContactAddress: this.ApplicationsContacts.ContactAddress,
                        Notes: this.ApplicationsContacts.Notes,
                    }
                    this.ruleForm.ApplicationsContacts.push(Obj);
                    this.resetForm(formName);
                    this.ApplicationsContacts.Age = "";
                    this.ApplicationsContacts.Phone = "";
                    this.ApplicationsContacts.Address = "";
                    this.ApplicationsContacts.ContactAddress = "";
                    this.ApplicationsContacts.Notes = "";

                } else {
                    return false;
                }
            });
        },

        DeleteApplicationsContacts(Listitem) {

            let indexToRemove = this.ruleForm.ApplicationsContacts.findIndex(item => item === Listitem);

            if (indexToRemove !== -1) {
                this.ruleForm.ApplicationsContacts.splice(indexToRemove, 1);
            }
        },



        submitApplicationsLabForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let Obj = {
                        Id: Number(0),
                        LaboratoriesId: this.ApplicationsLab.LaboratoriesId,
                        Laboratories: this.Laboratories.find(item => item.id === this.ApplicationsLab.LaboratoriesId).name,
                        ParasiteTypeId: this.ApplicationsLab.ParasiteTypeId,
                        ParasiteType: this.ParasiteTypes.find(item => item.id === this.ApplicationsLab.ParasiteTypeId).name,
                        TestTypesId: this.ApplicationsLab.TestTypesId,
                        TestTypes: this.TestTypes.find(item => item.id === this.ApplicationsLab.TestTypesId).name,
                        SampleTypeId: this.ApplicationsLab.SampleTypeId,
                        SampleType: this.SampleTypes.find(item => item.id === this.ApplicationsLab.SampleTypeId).name,
                        TokinDate: this.ApplicationsLab.TokinDate,
                        SendToLabOn: this.ApplicationsLab.SendToLabOn,
                        DoctorName: this.ApplicationsLab.DoctorName,
                        DoctorPhone: this.ApplicationsLab.DoctorPhone,
                        DoctorNotes: this.ApplicationsLab.DoctorNotes,
                        Result: this.ApplicationsLab.Result,
                        ResutlDescriptions: this.ApplicationsLab.ResutlDescriptions,
                        //ActionDate: this.ApplicationsLab.ActionDate,
                        Notes: this.ApplicationsLab.Notes,
                    }
                    this.ruleForm.ApplicationsLab.push(Obj);
                    this.resetForm(formName);
                    this.ApplicationsLab.TokinDate = "";
                    this.ApplicationsLab.SendToLabOn = "";
                    this.ApplicationsLab.DoctorName = "";
                    this.ApplicationsLab.DoctorPhone = "";
                    this.ApplicationsLab.DoctorNotes = "";
                    this.ApplicationsLab.Result = "";
                    this.ApplicationsLab.ResutlDescriptions = "";
                    this.ApplicationsLab.Notes = "";

                } else {
                    return false;
                }
            });
        },

        DeleteApplicationsLab(Listitem) {

            let indexToRemove = this.ruleForm.ApplicationsLab.findIndex(item => item === Listitem);

            if (indexToRemove !== -1) {
                this.ruleForm.ApplicationsLab.splice(indexToRemove, 1);
            }
        },



        submitApplicationsSymptomsForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let Obj = {
                        Id: Number(0),
                        SymptomsId: this.ApplicationsSymptoms.SymptomsId,
                        Symptoms: this.Symptoms.find(item => item.id === this.ApplicationsSymptoms.SymptomsId).name,
                        Notes: this.ApplicationsSymptoms.Notes,
                    }
                    this.ruleForm.ApplicationsSymptoms.push(Obj);
                    this.resetForm(formName);
                    this.ApplicationsSymptoms.Notes = "";

                } else {
                    return false;
                }
            });
        },

        DeleteApplicationsSymptoms(Listitem) {

            let indexToRemove = this.ruleForm.ApplicationsSymptoms.findIndex(item => item === Listitem);

            if (indexToRemove !== -1) {
                this.ruleForm.ApplicationsSymptoms.splice(indexToRemove, 1);
            }
        },



        submitApplicationsOrgansForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let Obj = {
                        Id: Number(0),
                        OrgansId: this.ApplicationsOrgans.OrgansId,
                        Organs: this.Organs.find(item => item.id === this.ApplicationsOrgans.OrgansId).name,
                        Notes: this.ApplicationsOrgans.Notes,
                    }
                    this.ruleForm.ApplicationsOrgans.push(Obj);
                    this.resetForm(formName);
                    this.ApplicationsOrgans.Notes = "";

                } else {
                    return false;
                }
            });
        },

        DeleteApplicationsOrgans(Listitem) {

            let indexToRemove = this.ruleForm.ApplicationsOrgans.findIndex(item => item === Listitem);

            if (indexToRemove !== -1) {
                this.ruleForm.ApplicationsOrgans.splice(indexToRemove, 1);
            }
        },


        submitApplicationsAnimalsContactsForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let Obj = {
                        Id: Number(0),
                        AnimalTypesId: this.ApplicationsAnimalsContacts.AnimalTypesId,
                        AnimalTypes: this.AnimalTypes.find(item => item.id === this.ApplicationsAnimalsContacts.AnimalTypesId).name,
                        ContactWay: this.ApplicationsAnimalsContacts.ContactWay,
                        ContactOn: this.ApplicationsAnimalsContacts.ContactOn,
                        ContactAddress: this.ApplicationsAnimalsContacts.ContactAddress,
                        Notes: this.ApplicationsAnimalsContacts.Notes,
                    }
                    this.ruleForm.ApplicationsAnimalsContacts.push(Obj);
                    this.resetForm(formName);
                    this.ApplicationsAnimalsContacts.ContactAddress = "";
                    this.ApplicationsAnimalsContacts.Notes = "";

                } else {
                    return false;
                }
            });
        },

        DeleteApplicationsAnimalsContacts(Listitem) {

            let indexToRemove = this.ruleForm.ApplicationsAnimalsContacts.findIndex(item => item === Listitem);

            if (indexToRemove !== -1) {
                this.ruleForm.ApplicationsAnimalsContacts.splice(indexToRemove, 1);
            }
        },




        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    //if (this.loginDetails.userType == 1) {
                    //    this.ruleForm.FacilitiesId = Number(0);
                    //    this.ruleForm.MunicipalitiesId = Number(0);
                    //}

                    this.ruleForm.Id = Number(0);
                    this.ruleForm.RequestsId = this.SelectedItem.id;
                    this.$blockUI.Start();
                    this.$http.AddRequestApplications(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetFinalForm(formName);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        resetFinalForm(formName) {
            this.$refs[formName].resetFields();

            this.ruleForm.OldWorkplace = '';
            this.ruleForm.SchoolName = '';
            this.ruleForm.DiedOn = '';
            this.ruleForm.Address = '';
            this.ruleForm.PatientsNotes = '';
            this.RemoveAllAttachment();
            this.ruleForm.Attachments = [];
            this.ruleForm.ApplicationsTravels = [];
            this.ruleForm.ApplicationsDiseasesHistory = [];
            this.ruleForm.ApplicationsBlodTransfusion = [];
            this.ruleForm.ApplicationsHospitalizations = [];
            this.ruleForm.ApplicationsInfected = [];
            this.ruleForm.ApplicationsContacts = [];
            this.ruleForm.ApplicationsLab = [];
            this.ruleForm.ApplicationsSymptoms = [];
            this.ruleForm.ApplicationsOrgans = [];
            this.ruleForm.ApplicationsAnimalsContacts = [];

        },





        SelectCoverAttachment(file) {

            let str = file.raw.type;
            str = str.substring(0, 5);


            if (str != "image" && str != "appli" && str != "text/" && str != "video") {
                this.$helper.ShowMessage('error', 'خطأ بالعملية', 'الرجاء التأكد من نوع الملف');
            }

            if (str != "image")
                file.url = this.ServerUrl + '/assets/img/small-logos/file.png';


            //var reader = new FileReader();
            //reader.readAsDataURL(file.raw);

            var $this = this;
            var reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = function () {

                if ($this.ruleForm && $this.ruleForm.Attachments.length !== 0) {
                    if (!$this.ruleForm.Attachments.some(item => item.ImageName === file.raw.name)) {
                        const obj = {
                            ImageName: file.raw.name,
                            ImageType: file.raw.type,
                            Url: file.url,
                            fileBase64: reader.result,
                        }
                        $this.ruleForm.Attachments.push(obj);
                    } else {
                        $this.$helper.ShowMessage('error', 'خطأ بالعملية', 'اسم الملف موجود مسبقا');
                        const imageUrl = file.url;
                        const imageElement = document.querySelector(`img[src="${imageUrl}"]`);
                        const parentElement = imageElement.parentNode;
                        const grandparentElement = parentElement.parentNode;

                        if (imageElement) {
                            grandparentElement.remove();
                        }
                    }
                } else {
                    const obj = {
                        ImageName: file.raw.name,
                        Url: file.url,
                        ImageType: file.raw.type,
                        fileBase64: reader.result,
                    }
                    $this.ruleForm.Attachments.push(obj);
                }

            };

        },

        handleRemove(file) {

            if (this.ruleForm && this.ruleForm.Attachments.length !== 0) {

                if (this.ruleForm.Attachments.some(item => item.ImageName === file.name)) {
                    const indexToDelete = this.ruleForm.Attachments.findIndex(item => item.ImageName === file.name);

                    if (indexToDelete !== -1) {
                        this.ruleForm.Attachments.splice(indexToDelete, 1);
                    }
                }
            }

            const imageUrl = file.url;
            const imageElement = document.querySelector(`img[src="${imageUrl}"]`);
            const parentElement = imageElement.parentNode;
            const grandparentElement = parentElement.parentNode;

            if (imageElement) {
                grandparentElement.remove();
            }
        },

        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },

        handleDownload(file) {
            const link = document.createElement('a');
            link.href = file.url;
            link.target = '_blank';
            link.download = '';
            link.click();
        },

        RemoveAllAttachment() {
            const elements = document.querySelectorAll('.el-upload-list--picture-card .el-upload-list__item');
            elements.forEach(element => element.remove());
            this.ruleForm.Attachments = [];
        },








        //*************************************************************End Of Add Info*************************************************************

























        


















        











        Back() {
            this.state = 0;
            this.SelectedItem = '';
            this.GetInfo(this.pageNo);
        },

        ChangeDate(date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("YYYY-MM-DD");
        },






    },
};
