<script>
    //import blockUI from './BlockUIService.js';
    import DataService from './DataService.js';
    import CryptoJS from 'crypto-js';
    export default {
        data() {
            return {
                loginDetails: null,



                Diseases: [],
                Cities: [],
                Municipalities: [],
                Facilities: [],
                AllFacilities: [],
                ForAllMunicipalities: [],
                Users: [],
                Nationalities: [],
                Occupations: [],
                Countries: [],
                ParasiteTypes: [],
                Specializations: [],
                TestTypes: [],
                TreatmentsTypes: [],
                Symptoms: [],
                Organs: [],
                Laboratories: [],
                LaboratoriesByMunicipalities: [],
                SampleTypes: [],
                AnimalTypes: [],



                //ChartByYear: [],


                /*Publeish*/
                //ServerUrl:'https://kidney.moh.com.ly',
                
                /*Local*/
                ServerUrl: 'http://localhost:5000',
                Facebock: 'https://www.facebook.com/p/Traneem-100064940462078/?locale=ar_AR',
                Instagram: 'https://www.instagram.com/traneem5__/',
                TraneemPhone: '+218 94 457 81 48',
                TraneemEmail: 'info@traneem.ly',
                PlatFormPass: 'Traneem!@Platformv1',


                WebSiteName: 'إدارة الرصد',
                BuildFor: 'إدارة الرصد الإستجابة السريعة ',
                logoPath: '/assets/img/1/3.png',



            }
        },
        methods: {

            encrypt: function encrypt(data, SECRET_KEY) {
                var dataSet = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY);
                dataSet = dataSet.toString();
                return dataSet;
            },
            decrypt: function decrypt(data, SECRET_KEY) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = JSON.parse(data.toString(CryptoJS.enc.Utf8));
                return data;
            },

            async CheckLoginStatus() {
                try {
                    this.loginDetails = JSON.parse(this.decrypt(localStorage.getItem('currentUser-client'), this.PlatFormPass));
                    if (this.loginDetails != null) {
                        const res = await DataService.IsLoggedin();
                        if (!res.data)
                            this.logout();
                    }
                } catch (error) {
                    //window.location.href = '/Login';
                }
            },

            async CheckLoginStatusRequierd() {
                try {
                    this.loginDetails = JSON.parse(this.decrypt(localStorage.getItem('currentUser-client'), this.PlatFormPass));
                    if (this.loginDetails != null) {
                        /*this.logout();*/
                    } else {
                        this.logout();
                    }
                } catch (error) {
                    this.logout();
                }
            },

            async logout() {
                localStorage.removeItem('currentUser-client');
                localStorage.clear();
                document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                this.$http.Logout()
                    .then(() => {
                        window.location.href = "/Login";
                    })
            },



            async GetAllDiseases(Type) {
                this.Diseases = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetDiseasesByType(Type);
                    this.Diseases = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllCities() {
                this.Cities = [];
                //blockUI.Start();
                try {
                    const res = await DataService.GetCitiesName();
                    this.Cities = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },
            async GetMunicipalities(id) {
                this.Municipalities = [];
                //blockUI.Start();
                try {
                    const res = await DataService.GetMunicipalitiesName(id);
                    this.Municipalities = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },
            async GetFacilities(id) {
                this.Facilities = [];
                //blockUI.Start();
                try {
                    const res = await DataService.GetFacilitiesName(id);
                    this.Facilities = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllFacilities() {
                this.AllFacilities = [];
                //blockUI.Start();
                try {
                    const res = await DataService.GetAllFacilitiesName();
                    this.AllFacilities = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllFacilitiesForAllMunicipalities() {
                this.ForAllMunicipalities = [];
                //blockUI.Start();
                try {
                    const res = await DataService.GetAllFacilitiesForAllMunicipalitiesName();
                    this.ForAllMunicipalities = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllLaboratories() {
                this.Laboratories = [];
                //blockUI.Start();
                try {
                    const res = await DataService.GetAllLaboratoriesName();
                    this.Laboratories = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetLaboratoriesByMunicipalitiesInfo(id) {
                this.LaboratoriesByMunicipalities = [];
                //blockUI.Start();
                try {
                    const res = await DataService.GetLaboratoriesName(id);
                    this.LaboratoriesByMunicipalities = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllSampleTypes(id) {
                this.SampleTypes = [];
                //blockUI.Start();
                try {
                    const res = await DataService.GetAllSampleTypesName(id);
                    this.SampleTypes = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllUsers() {
                this.Users = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetAllUsers();
                    this.Users = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllNationalities() {
                this.Nationalities = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetNationalitiesName();
                    this.Nationalities = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllOccupations() {
                this.Occupations = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetAllOccupations();
                    this.Occupations = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllGetAllCountries() {
                this.Countries = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetAllCountries();
                    this.Countries = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllParasiteTypesInfo() {
                this.ParasiteTypes = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetAllParasiteTypes();
                    this.ParasiteTypes = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllSpecializations() {
                this.Specializations = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetAllSpecializations();
                    this.Specializations = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetTestTypes(id) {
                this.TestTypes = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetAllTestTypes(id);
                    this.TestTypes = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetTreatmentsTypes(id) {
                this.TreatmentsTypes = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetAllTreatmentsTypes(id);
                    this.TreatmentsTypes = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllSymptoms(id) {
                this.Symptoms = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetAllDiseasesSymptoms(id);
                    this.Symptoms = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },


            async GetAllOrgans(id) {
                this.Organs = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetAllOrgans(id);
                    this.Organs = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },

            async GetAllAnimalTypes(id) {
                this.AnimalTypes = [];
                    //blockUI.Start();
                try {
                    const res = await DataService.GetAllAnimalTypes(id);
                    this.AnimalTypes = res.data.info;
                    //blockUI.Stop();
                } catch (err) {
                    //blockUI.Stop();
                }
            },








        }
    }
</script>
