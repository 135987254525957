import Vue from 'vue';
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import Vuetify from 'vuetify'
import locale from 'element-ui/lib/locale/lang/en'
import BlockUIService from './Shared/BlockUIService.js';
import App from './App.vue';
import Layout from './components/Layout/Layout.vue';
import Login from './components/Login/Login.vue';
import Home from './components/Home/Home.vue';
import DataService from './Shared/DataService';
import Helper from './Shared/Helper';

import Users from './components/Users/Users.vue';
import Profile from './components/Users/EditUsersProfile/EditUsersProfile.vue'






import AddApplications from "./components/Applications/Add/Add.vue";
import AddApplicationsWeekly from "./components/Applications/AddWeekly/Add.vue";

import Applications from "./components/Applications/Applications.vue";
import ApplicationsWeekly from "./components/Applications/ApplicationsWeekly/ApplicationsWeekly.vue";
import Requests from "./components/Applications/Requests/Requests.vue";
import TrackingRequest from "./components/Applications/Requests/Tracking/Tracking.vue";

import Laboratories from "./components/Laboratories/Laboratories.vue";
import LaboratoriesRequests from "./components/Laboratories/Requests/Requests.vue";

import Reports from "./components/Reports/Reports.vue";
import ReportsOne from "./components/Reports/ReportsOne/ReportsOne.vue";







import Diseases from './components/Dictionaries/Diseases/Diseases.vue';
import Cities from './components/Dictionaries/Cities/Cities.vue';
import Municipalities from './components/Dictionaries/Municipalities/Municipalities.vue';
import Facilities from './components/Dictionaries/Facilities/Facilities.vue';
import Nationalities from './components/Dictionaries/Nationalities/Nationalities.vue';
import Occupations from './components/Dictionaries/Occupations/Occupations.vue';
import Countries from './components/Dictionaries/Countries/Countries.vue';
import ParasiteTypes from './components/Dictionaries/ParasiteTypes/ParasiteTypes.vue';
import Specializations from './components/Dictionaries/Specializations/Specializations.vue';
import TestTypes from './components/Dictionaries/TestTypes/TestTypes.vue';
import TreatmentsTypes from './components/Dictionaries/TreatmentsTypes/TreatmentsTypes.vue';
import DiseasesSymptoms from './components/Dictionaries/DiseasesSymptoms/DiseasesSymptoms.vue';
import SampleTypes from './components/Dictionaries/SampleTypes/SampleTypes.vue';
import Organs from './components/Dictionaries/Organs/Organs.vue';
import AnimalTypes from './components/Dictionaries/AnimalTypes/AnimalTypes.vue';












import VueEllipseProgress from 'vue-ellipse-progress';

Vue.use(VueEllipseProgress);

Vue.use(Vuetify);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

Vue.prototype.$http = DataService;
Vue.prototype.$blockUI = BlockUIService;
Vue.prototype.$helper = Helper;

export const eventBus = new Vue();

//const i18n = new VueI18n({
//    locale: 'ar', // set locale
//    messages, // set locale messages
//})

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    linkActiveClass: "active",
    routes: [
        {
            path: "/Login",
            component: Login,
        },
        {
            path: "/",
            component: App,
            children: [
                {
                    path: "",
                    component: Layout,
                    children: [
                        { path: "", component: Home },


                        { path: "AddApplications", component: AddApplications},
                        { path: "AddApplicationsWeekly", component: AddApplicationsWeekly },

                        { path: "Applications", component: Applications },
                        { path: "ApplicationsWeekly", component: ApplicationsWeekly },
                        { path: "Requests", component: Requests },
                        { path: "TrackingRequest", component: TrackingRequest },


                        { path: "Reports", component: Reports },
                        { path: "ReportsOne", component: ReportsOne },


                        { path: "Laboratories", component: Laboratories },
                        { path: "LaboratoriesRequests", component: LaboratoriesRequests },


                        { path: "Diseases", component: Diseases },
                        { path: "Cities", component: Cities },
                        { path: "Municipalities", component: Municipalities },
                        { path: "Facilities", component: Facilities },
                        { path: "Nationalities", component: Nationalities },
                        { path: "Occupations", component: Occupations },
                        { path: "Countries", component: Countries },
                        { path: "ParasiteTypes", component: ParasiteTypes },
                        { path: "Specializations", component: Specializations },
                        { path: "TestTypes", component: TestTypes },
                        { path: "TreatmentsTypes", component: TreatmentsTypes },
                        { path: "DiseasesSymptoms", component: DiseasesSymptoms },
                        { path: "SampleTypes", component: SampleTypes },
                        { path: "Organs", component: Organs },
                        { path: "AnimalTypes", component: AnimalTypes },


                        { path: "Users", component: Users },
                        { path: "Profile", component: Profile },

                        
                    ],
                },
            ],
        },
    ],
});

Vue.filter("toUpperCase", function (value) {
    if (!value) return "";
    return value.toUpperCase();
});

new Vue({
    router,
    render: (h) => {
        return h(App);
    },
}).$mount("#cpanel-management");
