/*import swal from "sweetalert";*/

import moment from "moment";
import HelperMixin from '../../Shared/HelperMixin.vue'


export default {
    name: 'home',
    mixins: [HelperMixin],
   
    async created() {
        window.scrollTo(0, 0);

        this.GetInfo();

        setInterval(() => this.GetInfo(), 10800);

        await this.CheckLoginStatus();

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        this.dateNow = mm + "/" + dd + "/" + yyyy;

    },
    data() {
        return {

            pageSize: 5,
            pages: 0,
            state: 0,

            dateNow: '',

            Info: [],
        };
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("MMMM Do YYYY");
        },

        momentTime: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("Do/MM/YYYY || HH:mm");
        },

    },
    methods: {

        GetInfo() {
            this.$http.GetDashboardInfo()
                .then(response => {
                    this.Info = response.data.info;
                });
        },



    }    
}
