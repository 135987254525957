import Swal from "sweetalert2";
import moment from "moment";
//import { excelParser } from "./excel-parser";
import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: "AddUser",
    mixins: [HelperMixin],
    async created() {

        await this.CheckLoginStatus();
        await this.GetAllDiseases(2);
        await this.GetAllFacilities();
        await this.GetAllCities();
        await this.GetAllUsers();
        this.GetInfo();




        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        this.dateNow = mm + "/" + dd + "/" + yyyy;


    },

    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("MMMM Do YYYY");
        },

        momentTime: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("DD/MM/YYYY");
        },

        momentYear: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("YYYY");
        },

    },


    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,


            Info: [],
            Applications: [],


            ById: '',
            CitiesId: '',
            MunicipalitiesId: '',
            FacilitiesId: '',
            DiseasesId: '',
            ByDate: '',




            //Info
            SelectedItem: '',



            AddDilog: false,
            ViewDialog: false,
            AttachmentDiloag: false,
            dialogImageUrl: '',
            dialogVisible: false,





            ApplicationsLab: {
                Id: 0,
                DoctorName: '',
                DoctorPhone: '',
                DoctorNotes: '',
                Result: '',
                ResutlDescriptions: '',
                Notes: '',
            },

            ApplicationsLabrules: {
                DoctorName: this.$helper.Required(),
                DoctorPhone: this.$helper.Required(),
                DoctorNotes: this.$helper.Required(),
                Result: this.$helper.Required(),
                ResutlDescriptions: this.$helper.Required(),
            },




        };
    },


    methods: {

        async GetMunicipalitiesInfo() {
            this.Municipalities = [];
            await this.GetMunicipalities(this.CitiesId);
            this.GetInfo();
        },

        async GetFacilitiesInfo() {
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetFacilities(this.MunicipalitiesId);
            this.GetInfo();
        },



        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            if (this.ByDate)
                this.ByDate = this.ChangeDate(this.ByDate);

            this.$blockUI.Start();
            this.$http
                .GetApplicationsLabRequest(this.pageNo, this.pageSize, this.CitiesId, this.MunicipalitiesId, this.FacilitiesId, this.DiseasesId, this.ById, this.ByDate)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        FilterByNumber() {
            this.Applications = [];
            let code = "";
            if (document.getElementById('selectInputPass') != null) {

                if (document.getElementById('selectInputPass').value == null || document.getElementById('selectInputPass').value == '')
                    return;

                code = document.getElementById('selectInputPass').value;
            }
            if (code.length <= 1)
                return;

            this.$blockUI.Start();
            this.$http.GetApplicationsById(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Applications = response.data.info;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    console.error(err);
                });
        },


        Refresh() {
            this.CitiesId = '';
            this.MunicipalitiesId = '';
            this.FacilitiesId = '';
            this.DiseasesId = '';
            this.ById = '';
            this.ByDate = '';
            this.GetInfo();
        },






        OpenAddDilag(item) {
            this.SelectedItem = item;
            this.AddDilog = true;
        },

        submitApplicationsLabForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$blockUI.Start();
                    this.ApplicationsLab.Id = this.SelectedItem.id;
                    this.$http.AddLabResult(this.ApplicationsLab)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                            this.resetForm(formName);
                            this.ApplicationsLab.DoctorName = "";
                            this.ApplicationsLab.DoctorPhone = "";
                            this.ApplicationsLab.DoctorNotes = "";
                            this.ApplicationsLab.Result = "";
                            this.ApplicationsLab.ResutlDescriptions = "";
                            this.ApplicationsLab.Notes = "";
                            this.AddDilog = false;
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });



                } else {
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },








        ViewInfo(item) {
            this.SelectedItem = item;
            this.ViewDialog = true;
        },


























        Delete(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية الحذف ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteApplicationsLab(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },










        Back() {
            this.state = 0;
            this.SelectedItem = '';
            this.GetInfo(this.pageNo);
        },

        ChangeDate(date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("YYYY-MM-DD");
        },



    },
};
