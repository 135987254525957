////import Swal from "sweetalert2";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../../Shared/HelperMixin.vue'

export default {
    name: "Add",
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        await this.GetAllDiseases(1);
        await this.GetAllCities();

        
    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format("MMMM Do YYYY");
        },
    },
    data() {
        return {
            Offices: [],


            ruleForm: {
                Id: 0,


                DiseasesId: '',
                MunicipalitiesId: '',
                CityId: '',
                FacilitiesId: '',
                DateOn: '',

                CountCases: 0,
                Male: 0,
                Fmale: 0,
                UnknownGender: 0,

                Libyan: 0,
                Nlibyan: 0,
                UnknownNationality: 0,

                Deaths: 0,
                VaccinationStatus: 0,
                NotVaccinationStatus: 0,
                UnknownVaccinationStatus: 0,

                LessFive: 0,
                //OverFive: 0,
                LessTen: 0,
                LessFifteen: 0,
                LessTwenty: 0,
                LessTwentyFive: 0,
                LessThirty: 0,

                LessFiveThirty: 0,
                LessForty: 0,
                LessFortyFive: 0,
                OverFifty: 0,
                
            },
            rules: {
                
                DiseasesId: this.$helper.RequiredInput('المرض'),
                CityId: this.$helper.Required(),
                FacilitiesId: this.$helper.Required(),
                MunicipalitiesId: this.$helper.Required(),
                DateOn: this.$helper.RequiredInput('التاريخ'),

                CountCases: this.$helper.RequiredInput(' عدد الحالات'),
                Male: this.$helper.RequiredInput(' عدد الذكور'),
                Fmale: this.$helper.RequiredInput(' عدد الإناث'),
                UnknownGender: this.$helper.RequiredInput(' غير مفوفي الجنسية '),

                Libyan: this.$helper.RequiredInput(' عدد الليبين  '),
                Nlibyan: this.$helper.RequiredInput(' عدد الاجانب '),
                UnknownNationality: this.$helper.RequiredInput(' عدد الغير معروف الجنسية '),

                Deaths: this.$helper.RequiredInput(' عدد المتوفين '),
                VaccinationStatus: this.$helper.RequiredInput(' عدد المطعمين '),
                NotVaccinationStatus: this.$helper.RequiredInput(' عدد الغير مطعمين '),
                UnknownVaccinationStatus: this.$helper.RequiredInput(' غير معروف حالةالتطعيم '),

                LessFive: this.$helper.Required(),
                LessTen: this.$helper.Required(),
                LessFifteen: this.$helper.Required(),
                LessTwenty: this.$helper.Required(),
                LessTwentyFive: this.$helper.Required(),
                LessThirty: this.$helper.Required(),

                LessFiveThirty: this.$helper.Required(),
                LessForty: this.$helper.Required(),
                LessFortyFive: this.$helper.Required(),
                OverFifty: this.$helper.Required(),

            },

        };
    },
    
    methods: {

        async GetMunicipalitiesInfo() {
            this.ruleForm.MunicipalitiesId = '';
            this.Municipalities = [];
            this.ruleForm.FacilitiesId = '';
            this.Facilities = [];

            await this.GetMunicipalities(this.ruleForm.CityId);
        },

        async GetFacilitiesInfo() {
            this.ruleForm.FacilitiesId = '';
            this.Facilities = [];
            await this.GetFacilities(this.ruleForm.MunicipalitiesId);
        },


        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    if (this.loginDetails.userType != 1) {
                        this.ruleForm.FacilitiesId = Number(0);
                        this.ruleForm.MunicipalitiesId = Number(0);
                    } else {
                        this.ruleForm.FacilitiesId = Number(this.ruleForm.FacilitiesId);
                        this.ruleForm.MunicipalitiesId = Number(this.ruleForm.MunicipalitiesId);
                    }



                    this.ruleForm.Id = Number(0);
                    this.ruleForm.CountCases = Number(this.ruleForm.CountCases);
                    this.ruleForm.Male = Number(this.ruleForm.Male);
                    this.ruleForm.Fmale = Number(this.ruleForm.Fmale);
                    this.ruleForm.UnknownGender = Number(this.ruleForm.UnknownGender);

                    this.ruleForm.Libyan = Number(this.ruleForm.Libyan);
                    this.ruleForm.Nlibyan = Number(this.ruleForm.Nlibyan);
                    this.ruleForm.UnknownNationality = Number(this.ruleForm.UnknownNationality);

                    this.ruleForm.Deaths = Number(this.ruleForm.Deaths);
                    this.ruleForm.VaccinationStatus = Number(this.ruleForm.VaccinationStatus);
                    this.ruleForm.NotVaccinationStatus = Number(this.ruleForm.NotVaccinationStatus);
                    this.ruleForm.UnknownVaccinationStatus = Number(this.ruleForm.UnknownVaccinationStatus);

                    //this.ruleForm.OverFive = Number(this.ruleForm.OverFive);
                    this.ruleForm.LessFive = Number(this.ruleForm.LessFive);
                    this.ruleForm.LessTen = Number(this.ruleForm.LessTen);
                    this.ruleForm.LessFifteen = Number(this.ruleForm.LessFifteen);
                    this.ruleForm.LessTwenty = Number(this.ruleForm.LessTwenty);
                    this.ruleForm.LessTwentyFive = Number(this.ruleForm.LessTwentyFive);
                    this.ruleForm.LessThirty = Number(this.ruleForm.LessThirty);
                    this.ruleForm.LessFiveThirty = Number(this.ruleForm.LessFiveThirty);
                    this.ruleForm.LessForty = Number(this.ruleForm.LessForty);
                    this.ruleForm.LessFortyFive = Number(this.ruleForm.LessFortyFive);
                    this.ruleForm.OverFifty = Number(this.ruleForm.OverFifty);

                    this.$blockUI.Start();
                    this.$http.AddWeeklyApplications(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();

            this.ruleForm.CountCases = 0;
            this.ruleForm.Male = 0;
            this.ruleForm.Fmale = 0;
            this.ruleForm.UnknownGender = 0;

            this.ruleForm.Libyan = 0;
            this.ruleForm.Nlibyan = 0;
            this.ruleForm.UnknownNationality = 0;

            this.ruleForm.Deaths = 0;
            this.ruleForm.VaccinationStatus = 0;
            this.ruleForm.NotVaccinationStatus = 0;
            this.ruleForm.UnknownVaccinationStatus = 0;
            
            
            //this.ruleForm.OverFive = 0;
            this.ruleForm.LessFive = 0;
            this.ruleForm.LessTen = 0;
            this.ruleForm.LessFifteen = 0;
            this.ruleForm.LessTwenty = 0;
            this.ruleForm.LessTwentyFive = 0;
            this.ruleForm.LessThirty = 0;
            this.ruleForm.LessFiveThirty = 0;
            this.ruleForm.LessForty = 0;
            this.ruleForm.LessFortyFive = 0;
            this.ruleForm.OverFifty = 0;
        },

      
    },
};
