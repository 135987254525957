import axios from "axios";

axios.defaults.headers.common["X-CSRF-TOKEN"] = document.cookie.split("=")[1];

//const baseUrl = 'http://localhost:4810/Api';

export default {
    // ********************************************************************| Authintecations |***********************************************************

    login(bodyObjeect) {
        return axios.post(`/Security/loginUser`, bodyObjeect);
    },

    AddMuntisbl(bodyObjeect) {
        return axios.post(`/api/admin/Municipalities/Add`, bodyObjeect);
    },

    IsLoggedin() {
        return axios.get(`/Security/IsLoggedin`);
    },

    Logout() {
        return axios.post(`/Security/Logout`);
    },



    // ********************************************************************| Dictionaries |***********************************************************
    // Diseases
    GetDiseases(pageNo, pageSize, Type) {
        return axios.get(`api/admin/Dictionaries/Diseases/Get?pageno=${pageNo}&pagesize=${pageSize}&Type=${Type}`);
    },

    GetDiseasesByType(Type) {
        return axios.get(`api/admin/Dictionaries/Diseases/GetByType?Type=${Type}`);
    },

    AddDiseases(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Diseases/Add`, bodyObject);
    },

    EditDiseases(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Diseases/Edit`, bodyObject);
    },

    DeleteDiseases(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Diseases/Delete`);
    },


    // Office
    GetOffices(pageNo, pageSize, Type) {
        return axios.get(`api/admin/Dictionaries/Offices/Get?pageno=${pageNo}&pagesize=${pageSize}&Type=${Type}`);
    },

    GetOfficesById(Id) {
        return axios.get(`api/admin/Dictionaries/Offices/GetByType?Type=${Id}`);
    },

    AddOffices(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Offices/Add`, bodyObject);
    },

    EditOffices(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Offices/Edit`, bodyObject);
    },

    DeleteOffices(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Offices/Delete`);
    },


    // Subcommitteies
    GetSubcommitteies(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Subcommitteies/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllSubcommitteies() {
        return axios.get(`api/admin/Dictionaries/Subcommitteies/GetAll`);
    },

    AddSubcommitteies(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Subcommitteies/Add`, bodyObject);
    },

    EditSubcommitteies(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Subcommitteies/Edit`, bodyObject);
    },

    DeleteSubcommitteies(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Subcommitteies/Delete`);
    },


    // Lands
    GetLands(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Lands/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllLands() {
        return axios.get(`api/admin/Dictionaries/Lands/GetAll`);
    },

    AddLands(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Lands/Add`, bodyObject);
    },

    EditLands(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Lands/Edit`, bodyObject);
    },

    DeleteLands(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Lands/Delete`);
    },


    // Decisions
    GetDecisions(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Decisions/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllDecisions() {
        return axios.get(`api/admin/Dictionaries/Decisions/GetAll`);
    },

    AddDecisions(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Decisions/Add`, bodyObject);
    },

    EditDecisions(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Decisions/Edit`, bodyObject);
    },

    DeleteDecisions(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Decisions/Delete`);
    },


    // Cities
    GetCities(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Cities/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetCitiesName() {
        return axios.get(`/Api/Admin/Dictionaries/Cities/GetAll`);
    },

    AddCities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Cities/Add`, bodyObject);
    },

    EditCities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Cities/Edit`, bodyObject);
    },

    DeleteCities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Cities/Delete`);
    },


    // Municipalities
    GetMunicipalities(pageNo, pageSize, CityId) {
        return axios.get(`api/admin/Dictionaries/Municipalities/Get?pageno=${pageNo}&pagesize=${pageSize}&CityId=${CityId}`);
    },

    GetMunicipalitiesName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Municipalities/GetById?id=${id}`);
    },

    AddMunicipalities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Municipalities/Add`, bodyObject);
    },

    EditMunicipalities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Municipalities/Edit`, bodyObject);
    },

    DeleteMunicipalities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Municipalities/Delete`);
    },


    // Facilities
    GetFacilities(pageNo, pageSize, Municipalitiesid) {
        return axios.get(`api/admin/Dictionaries/Facilities/Get?pageno=${pageNo}&pagesize=${pageSize}&Municipalitiesid=${Municipalitiesid}`);
    },

    GetFacilitiesName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Facilities/GetById?id=${id}`);
    },

    GetAllFacilitiesName() {
        return axios.get(`/Api/Admin/Dictionaries/Facilities/GetAll`);
    },

    GetAllFacilitiesForAllMunicipalitiesName() {
        return axios.get(`/Api/Admin/Dictionaries/Facilities/GetAllForAllMunicipalities`);
    },

    AddFacilities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Facilities/Add`, bodyObject);
    },

    EditFacilities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Facilities/Edit`, bodyObject);
    },

    DeleteFacilities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Facilities/Delete`);
    },




    // Laboratories
    GetLaboratories(pageNo, pageSize, Municipalitiesid) {
        return axios.get(`api/admin/Dictionaries/Laboratories/Get?pageno=${pageNo}&pagesize=${pageSize}&Municipalitiesid=${Municipalitiesid}`);
    },

    GetLaboratoriesName(id) {
        return axios.get(`/Api/Admin/Dictionaries/Laboratories/GetById?id=${id}`);
    },

    GetAllLaboratoriesName() {
        return axios.get(`/Api/Admin/Dictionaries/Laboratories/GetAll`);
    },

    AddLaboratories(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Laboratories/Add`, bodyObject);
    },

    EditLaboratories(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Laboratories/Edit`, bodyObject);
    },

    DeleteLaboratories(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Laboratories/Delete`);
    },







    // Nationalities
    GetNationalities(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Nationalities/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetNationalitiesName() {
        return axios.get(`/Api/Admin/Dictionaries/Nationalities/GetAll`);
    },

    AddNationalities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Nationalities/Add`, bodyObject);
    },

    EditNationalities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Nationalities/Edit`, bodyObject);
    },

    DeleteNationalities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Nationalities/Delete`);
    },










    //Occupations
    GetOccupations(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Occupations/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllOccupations() {
        return axios.get(`api/admin/Dictionaries/Occupations/GetAll`);
    },

    AddOccupations(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Occupations/Add`, bodyObject);
    },

    EditOccupations(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Occupations/Edit`, bodyObject);
    },

    DeleteOccupations(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Occupations/Delete`);
    },








    //Countries
    GetCountries(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Countries/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllCountries() {
        return axios.get(`api/admin/Dictionaries/Countries/GetAll`);
    },

    AddCountries(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Countries/Add`, bodyObject);
    },

    EditCountries(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Countries/Edit`, bodyObject);
    },

    DeleteCountries(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Countries/Delete`);
    },





    //ParasiteTypes
    GetParasiteTypes(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/ParasiteTypes/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllParasiteTypes() {
        return axios.get(`api/admin/Dictionaries/ParasiteTypes/GetAll`);
    },

    AddParasiteTypes(bodyObject) {
        return axios.post(`api/admin/Dictionaries/ParasiteTypes/Add`, bodyObject);
    },

    EditParasiteTypes(bodyObject) {
        return axios.post(`api/admin/Dictionaries/ParasiteTypes/Edit`, bodyObject);
    },

    DeleteParasiteTypes(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/ParasiteTypes/Delete`);
    },





    //Specializations
    GetSpecializations(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Specializations/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllSpecializations() {
        return axios.get(`api/admin/Dictionaries/Specializations/GetAll`);
    },

    AddSpecializations(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Specializations/Add`, bodyObject);
    },

    EditSpecializations(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Specializations/Edit`, bodyObject);
    },

    DeleteSpecializations(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Specializations/Delete`);
    },



    //TestTypes
    GetTestTypes(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/TestTypes/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllTestTypes(id) {
        return axios.get(`api/admin/Dictionaries/TestTypes/GetById?DiseasesId=${id}`);
    },

    AddTestTypes(bodyObject) {
        return axios.post(`api/admin/Dictionaries/TestTypes/Add`, bodyObject);
    },

    EditTestTypes(bodyObject) {
        return axios.post(`api/admin/Dictionaries/TestTypes/Edit`, bodyObject);
    },

    DeleteTestTypes(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/TestTypes/Delete`);
    },





    //TreatmentsTypes
    GetTreatmentsTypes(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/TreatmentsTypes/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllTreatmentsTypes(id) {
        return axios.get(`api/admin/Dictionaries/TreatmentsTypes/GetById?DiseasesId=${id}`);
    },

    AddTreatmentsTypes(bodyObject) {
        return axios.post(`api/admin/Dictionaries/TreatmentsTypes/Add`, bodyObject);
    },

    EditTreatmentsTypes(bodyObject) {
        return axios.post(`api/admin/Dictionaries/TreatmentsTypes/Edit`, bodyObject);
    },

    DeleteTreatmentsTypes(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/TreatmentsTypes/Delete`);
    },





    // DiseasesSymptoms
    GetDiseasesSymptoms(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/DiseasesSymptoms/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllDiseasesSymptoms(Id) {
        return axios.get(`api/admin/Dictionaries/DiseasesSymptoms/GetAll?Id=${Id}`);
    },

    AddDiseasesSymptoms(bodyObject) {
        return axios.post(`api/admin/Dictionaries/DiseasesSymptoms/Add`, bodyObject);
    },

    EditDiseasesSymptoms(bodyObject) {
        return axios.post(`api/admin/Dictionaries/DiseasesSymptoms/Edit`, bodyObject);
    },

    DeleteDiseasesSymptoms(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/DiseasesSymptoms/Delete`);
    },


    // Organs
    GetOrgans(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Organs/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllOrgans(Id) {
        return axios.get(`api/admin/Dictionaries/Organs/GetAll?Id=${Id}`);
    },

    AddOrgans(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Organs/Add`, bodyObject);
    },

    EditOrgans(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Organs/Edit`, bodyObject);
    },

    DeleteOrgans(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Organs/Delete`);
    },


    // AnimalTypes
    GetAnimalTypes(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/AnimalTypes/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllAnimalTypes(Id) {
        return axios.get(`api/admin/Dictionaries/AnimalTypes/GetAll?Id=${Id}`);
    },

    AddAnimalTypes(bodyObject) {
        return axios.post(`api/admin/Dictionaries/AnimalTypes/Add`, bodyObject);
    },

    EditAnimalTypes(bodyObject) {
        return axios.post(`api/admin/Dictionaries/AnimalTypes/Edit`, bodyObject);
    },

    DeleteAnimalTypes(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/AnimalTypes/Delete`);
    },



    // SampleTypes
    GetSampleTypes(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/SampleTypes/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllSampleTypesName(Id) {
        return axios.get(`api/admin/Dictionaries/SampleTypes/GetAll?Id=${Id}`);
    },

    AddSampleTypes(bodyObject) {
        return axios.post(`api/admin/Dictionaries/SampleTypes/Add`, bodyObject);
    },

    EditSampleTypes(bodyObject) {
        return axios.post(`api/admin/Dictionaries/SampleTypes/Edit`, bodyObject);
    },

    DeleteSampleTypes(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/SampleTypes/Delete`);
    },





    // ********************************************************************| Applications |***********************************************************


    AddWeeklyApplications(bodyObject) {
        return axios.post("api/admin/Applications/Weekly/Add", bodyObject);
    },

    EditWeeklyApplications(bodyObject) {
        return axios.post("api/admin/Applications/Weekly/Edit", bodyObject);
    },

    AddApplications(bodyObject) {
        return axios.post("api/admin/Applications/Add", bodyObject);
    },


    GetApplications(pageNo, pageSize, CitiesId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate) {
        return axios.get(`api/admin/Applications/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &CitiesId=${CitiesId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}`);
    },

    GetApplicationsById(code) {
        return axios.get(`api/admin/Applications/GetById?code=${code}`);
    },

    GetApplicationsWeekly(pageNo, pageSize, CitiesId, Municipalitiesid, FacilitiesId, DiseasesId, ByDate) {
        return axios.get(`api/admin/Applications/Weekly/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &CitiesId=${CitiesId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ByDate=${ByDate}`);
    },


    DeleteApplicationsWeekly(Id) {
        return axios.post(`api/admin/Applications/${Id}/Weekly/Delete`);
    },




    // Request
    GetApplicationsRequests(pageNo, pageSize, CitiesId, MunicipalitiesId, FacilitiesId, DiseasesId, ById, ByDate) {
        return axios.get(`api/admin/Applications/Requests/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &CitiesId=${CitiesId}&MunicipalitiesId=${MunicipalitiesId}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}`);
    },

    GetRequestsById(code) {
        return axios.get(`api/admin/Applications/Requests/GetById?code=${code}`);
    },

    GetAllReuestInfo() {
        return axios.get(`api/admin/Applications/Requests/GetAll`);
    },


    AddRequest(bodyObject) {
        return axios.post("api/admin/Applications/Request/Add", bodyObject);
    },

    AddRequestApplications(bodyObject) {
        return axios.post("api/admin/Applications/Request/AddApplications", bodyObject);
    },

    GetApplicationsRequestsName(Id) {
        return axios.get(`api/admin/Applications/Requests/GetName?Id=${Id}`);
    },

    GetApplicationsRequest(pageNo, pageSize, CitiesId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate, RequestsId) {
        return axios.get(`api/admin/Applications/Requests/GetApplications?pageNo=${pageNo}&pagesize=${pageSize}
            &CitiesId=${CitiesId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}&RequestsId=${RequestsId}`);
    },





    //ApplicationsAttachments
    DeleteApplicationsAttachments(Id) {
        return axios.post(`api/admin/Applications/${Id}/Attachments/Delete`);
    },

    AddApplicationsAttachments(bodyObject) {
        return axios.post("api/admin/Applications/Attachments/Add", bodyObject);
    },



    //Travel
    DeleteApplicationsTravel(Id) {
        return axios.post(`api/admin/Applications/${Id}/Travel/Delete`);
    },

    AddApplicationsTravel(bodyObject) {
        return axios.post("api/admin/Applications/Travel/Add", bodyObject);
    },

    GetApplicationsTravel(Id) {
        return axios.get(`api/admin/Applications/Travel/Get?Id=${Id}`);
    },



    //DiseasesHistory
    AddApplicationsDiseasesHistory(bodyObject) {
        return axios.post("api/admin/Applications/DiseasesHistory/Add", bodyObject);
    },

    GetDiseasesHistory(Id) {
        return axios.get(`api/admin/Applications/DiseasesHistory/Get?Id=${Id}`);
    },

    DeleteDiseasesHistory(Id) {
        return axios.post(`api/admin/Applications/${Id}/DiseasesHistory/Delete`);
    },


    //BlodTransfusion
    AddApplicationsBlodTransfusion(bodyObject) {
        return axios.post("api/admin/Applications/BlodTransfusion/Add", bodyObject);
    },

    GetApplicationsBlodTransfusion(Id) {
        return axios.get(`api/admin/Applications/BlodTransfusion/Get?Id=${Id}`);
    },

    DeleteApplicationsBlodTransfusion(Id) {
        return axios.post(`api/admin/Applications/${Id}/BlodTransfusion/Delete`);
    },



    //Hospitalizations
    AddApplicationsHospitalizations(bodyObject) {
        return axios.post("api/admin/Applications/Hospitalizations/Add", bodyObject);
    },

    GetApplicationsHospitalizations(Id) {
        return axios.get(`api/admin/Applications/Hospitalizations/Get?Id=${Id}`);
    },

    DeleteApplicationsHospitalizations(Id) {
        return axios.post(`api/admin/Applications/${Id}/Hospitalizations/Delete`);
    },



    //ApplicationsInfected
    AddApplicationsInfected(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsInfected/Add", bodyObject);
    },

    GetApplicationsInfected(Id) {
        return axios.get(`api/admin/Applications/ApplicationsInfected/Get?Id=${Id}`);
    },

    DeleteApplicationsInfected(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsInfected/Delete`);
    },



    //ApplicationsContacts
    AddApplicationsContacts(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsContacts/Add", bodyObject);
    },

    GetApplicationsContacts(Id) {
        return axios.get(`api/admin/Applications/ApplicationsContacts/Get?Id=${Id}`);
    },

    DeleteApplicationsContacts(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsContacts/Delete`);
    },


    //ApplicationsLab
    AddApplicationsLab(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsLab/Add", bodyObject);
    },

    GetApplicationsLab(Id) {
        return axios.get(`api/admin/Applications/ApplicationsLab/Get?Id=${Id}`);
    },

    DeleteApplicationsLab(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsLab/Delete`);
    },



    //ApplicationsSymptoms
    AddApplicationsSymptoms(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsSymptoms/Add", bodyObject);
    },

    GetApplicationsSymptoms(Id) {
        return axios.get(`api/admin/Applications/ApplicationsSymptoms/Get?Id=${Id}`);
    },

    DeleteApplicationsSymptoms(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsSymptoms/Delete`);
    },





    //ApplicationsOrgans
    AddApplicationsOrgans(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsOrgans/Add", bodyObject);
    },

    GetApplicationsOrgans(Id) {
        return axios.get(`api/admin/Applications/ApplicationsOrgans/Get?Id=${Id}`);
    },

    DeleteApplicationsOrgans(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsOrgans/Delete`);
    },




    //ApplicationsAnimalsContacts
    AddApplicationsAnimalsContacts(bodyObject) {
        return axios.post("api/admin/Applications/ApplicationsAnimalsContacts/Add", bodyObject);
    },

    GetApplicationsAnimalsContacts(Id) {
        return axios.get(`api/admin/Applications/ApplicationsAnimalsContacts/Get?Id=${Id}`);
    },

    DeleteApplicationsAnimalsContacts(Id) {
        return axios.post(`api/admin/Applications/${Id}/ApplicationsAnimalsContacts/Delete`);
    },



















    // Lab
    AddLab(bodyObject) {
        return axios.post("api/admin/Applications/Lab/Add", bodyObject);
    },

    GetApplicationsLabRequest(pageNo, pageSize, CitiesId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate) {
        return axios.get(`api/admin/Lab/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &CitiesId=${CitiesId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}`)
    },

    AddLabResult(bodyObject) {
        return axios.post("api/admin/Lab/AddResult", bodyObject);
    },

    DeleteApplicationsLabRequest(Id) {
        return axios.post(`api/admin/Lab/${Id}/Delete`);
    },




    // ********************************************************************| Reports |***********************************************************


    GetMainReport(CitiesId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate, From, To) {
        return axios.get(`api/admin/Reports/Get?CitiesId=${CitiesId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}&From=${From}&To=${To}`)
    },

    GetMainReportOne(CitiesId, Municipalitiesid, FacilitiesId, DiseasesId, ById, ByDate, From, To) {
        return axios.get(`api/admin/Reports/GetOne?CitiesId=${CitiesId}&Municipalitiesid=${Municipalitiesid}&FacilitiesId=${FacilitiesId}&DiseasesId=${DiseasesId}
            &ById=${ById}&ByDate=${ByDate}&From=${From}&To=${To}`)
    },










    // ********************************************************************| Users |***********************************************************
    GetUsers(pageNo, pageSize, Type, OfficeId) {
        return axios.get(`api/admin/User/Get?pageNo=${pageNo}&pagesize=${pageSize}&Type=${Type}&OfficeId=${OfficeId}`);
    },

    GetAllUsers() {
        return axios.get(`api/admin/User/GetAll`);
    },

    AddUser(bodyObject) {
        return axios.post("api/admin/User/Add", bodyObject);
    },

    EditUser(bodyObject) {
        return axios.post("api/admin/User/Edit", bodyObject);
    },

    ChangeStatusUser(Id) {
        return axios.post(`api/admin/User/${Id}/ChangeStatus`);
    },

    RestePassword(Id) {
        return axios.post(`api/admin/User/${Id}/RestePassword`);
    },

    DeleteUser(Id) {
        return axios.post(`api/admin/User/${Id}/Delete`);
    },

    UploadImage(bodyObject) {
        return axios.post("/Api/Admin/User/UploadImage", bodyObject);
    },

    EditUsersProfile(bodyObject) {
        return axios.post("/Api/Admin/User/EditUsersProfile", bodyObject);
    },

    ChangePassword(userPassword) {
        return axios.post(`/Api/Admin/User/ChangePassword`, userPassword);
    },











    // ********************************************************************| Dashboard Info |***********************************************************
    GetDashboardInfo() {
        return axios.get(`/api/admin/Reports/GetDashboardInfo`);
    },


























};
