import Swal from "sweetalert2";
import moment from "moment";
//import { excelParser } from "./excel-parser";
import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: "AddUser",
    mixins: [HelperMixin],
    async created() {

        await this.CheckLoginStatus();
        await this.GetAllDiseases(1);
        await this.GetAllCities();
        await this.GetAllUsers();
        this.GetInfo();




        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        this.dateNow = mm + "/" + dd + "/" + yyyy;


    },

    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("MMMM Do YYYY");
        },

        momentTime: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("DD/MM/YYYY");
        },

        momentYear: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("YYYY");
        },

    },


    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,


            Info: [],



            ById: '',
            CitiesId: '',
            MunicipalitiesId: '',
            FacilitiesId: '',
            DiseasesId: '',
            ByDate: '',



            Job: [],



            //Info
            SelectedItem: '',



            EditDilog: false,
            RequestDilog: false,
            AttachmentDiloag: false,
            dialogImageUrl: '',
            dialogVisible: false,





            RequestruleForm: {
                Id: 0,
                DailiesFormId: '',
                CountCases: '',
                Desriptions: '',
            },



            Requestrules: {
                CountCases: this.$helper.RequiredInput(' عدد الحالات  '),
            },


            ruleForm: {
                Id: 0,


                DiseasesId: '',
                FacilitiesId: '',
                Municipalitiesid: '',
                DateOn: '',

                CountCases: 0,
                Male: 0,
                Fmale: 0,
                UnknownGender: 0,

                Libyan: 0,
                Nlibyan: 0,
                UnknownNationality: 0,

                Deaths: 0,
                VaccinationStatus: 0,
                NotVaccinationStatus: 0,
                UnknownVaccinationStatus: 0,

                LessFive: 0,
                //OverFive: 0,
                LessTen: 0,
                LessFifteen: 0,
                LessTwenty: 0,
                LessTwentyFive: 0,
                LessThirty: 0,

                LessFiveThirty: 0,
                LessForty: 0,
                LessFortyFive: 0,
                OverFifty: 0,

            },
            rules: {

                DiseasesId: this.$helper.RequiredInput('المرض'),
                FacilitiesId: this.$helper.Required(),
                Municipalitiesid: this.$helper.Required(),
                DateOn: this.$helper.RequiredInput('التاريخ'),

                CountCases: this.$helper.RequiredInput(' عدد الحالات'),
                Male: this.$helper.RequiredInput(' عدد الذكور'),
                Fmale: this.$helper.RequiredInput(' عدد الإناث'),
                UnknownGender: this.$helper.RequiredInput(' غير مفوفي الجنسية '),

                Libyan: this.$helper.RequiredInput(' عدد الليبين  '),
                Nlibyan: this.$helper.RequiredInput(' عدد الاجانب '),
                UnknownNationality: this.$helper.RequiredInput(' عدد الغير معروف الجنسية '),

                Deaths: this.$helper.RequiredInput(' عدد المتوفين '),
                VaccinationStatus: this.$helper.RequiredInput(' عدد المطعمين '),
                NotVaccinationStatus: this.$helper.RequiredInput(' عدد الغير مطعمين '),
                UnknownVaccinationStatus: this.$helper.RequiredInput(' غير معروف حالةالتطعيم '),

                LessFive: this.$helper.Required(),
                LessTen: this.$helper.Required(),
                LessFifteen: this.$helper.Required(),
                LessTwenty: this.$helper.Required(),
                LessTwentyFive: this.$helper.Required(),
                LessThirty: this.$helper.Required(),

                LessFiveThirty: this.$helper.Required(),
                LessForty: this.$helper.Required(),
                LessFortyFive: this.$helper.Required(),
                OverFifty: this.$helper.Required(),

            },

        };
    },


    methods: {

        async GetMunicipalitiesInfo() {
            this.GetInfo();
            this.Municipalities = [];
            await this.GetMunicipalities(this.CitiesId);
        },

        async GetFacilitiesInfo() {
            this.GetInfo();
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetFacilities(this.MunicipalitiesId);
        },



        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            if (this.ByDate)
                this.ByDate = this.ChangeDate(this.ByDate);

            this.$blockUI.Start();
            this.$http
                .GetApplicationsWeekly(this.pageNo, this.pageSize, this.CitiesId, this.Municipalitiesid, this.FacilitiesId, this.DiseasesId, this.ByDate)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        Refresh() {
            this.CitiesId = '';
            this.Municipalitiesid = '';
            this.FacilitiesId = '';
            this.DiseasesId = '';
            this.ById = '';
            this.ByDate = '';
            this.GetInfo();
        },





        OpenRequestDilog(item) {
            this.SelectedItem = item;
            this.RequestDilog = true;
        },

        submitRequestForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.RequestruleForm.Id = Number(0);
                    this.RequestruleForm.CountCases = Number(this.RequestruleForm.CountCases);
                    this.RequestruleForm.DailiesFormId = this.SelectedItem.id;
                    this.$blockUI.Start();
                    this.$http.AddRequest(this.RequestruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.GetInfo();
                            this.RequestDilog = false;
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بالعملية  ', err.response.data);
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },






        OpenEditDilaog(item) {
            this.SelectedItem = item;
            this.ruleForm.Id = item.id;
            this.ruleForm.DiseasesId = item.diseasesId;
            this.ruleForm.FacilitiesId = item.facilitiesId;
            this.ruleForm.Municipalitiesid = item.municipalitiesid;
            this.ruleForm.DateOn = item.dateOn;

            this.ruleForm.CountCases = item.countCases;
            this.ruleForm.Male = item.male;
            this.ruleForm.Fmale = item.fmale;
            this.ruleForm.UnknownGender = item.unknownGender;

            this.ruleForm.Libyan = item.libyan;
            this.ruleForm.Nlibyan = item.nlibyan;
            this.ruleForm.UnknownNationality = item.unknownNationality;

            this.ruleForm.Deaths = item.deaths;
            this.ruleForm.VaccinationStatus = item.vaccinationStatus;
            this.ruleForm.NotVaccinationStatus = item.notVaccinationStatus;
            this.ruleForm.UnknownVaccinationStatus = item.unknownVaccinationStatus;

            this.ruleForm.LessFive = item.lessFive;
            this.ruleForm.LessTen = item.lessTen;
            this.ruleForm.LessFifteen = item.lessFifteen;
            this.ruleForm.LessTwenty = item.lessTwenty;
            this.ruleForm.LessTwentyFive = item.lessTwentyFive;
            this.ruleForm.LessThirty = item.lessThirty;

            this.ruleForm.LessFiveThirty = item.lessFiveThirty;
            this.ruleForm.LessForty = item.lessForty;
            this.ruleForm.LessFortyFive = item.lessFortyFive;
            this.ruleForm.OverFifty = item.overFifty;

            this.EditDilog = true;

        },  


        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {


                    this.ruleForm.CountCases = Number(this.ruleForm.CountCases);
                    this.ruleForm.Male = Number(this.ruleForm.Male);
                    this.ruleForm.Fmale = Number(this.ruleForm.Fmale);
                    this.ruleForm.UnknownGender = Number(this.ruleForm.UnknownGender);

                    this.ruleForm.Libyan = Number(this.ruleForm.Libyan);
                    this.ruleForm.Nlibyan = Number(this.ruleForm.Nlibyan);
                    this.ruleForm.UnknownNationality = Number(this.ruleForm.UnknownNationality);

                    this.ruleForm.Deaths = Number(this.ruleForm.Deaths);
                    this.ruleForm.VaccinationStatus = Number(this.ruleForm.VaccinationStatus);
                    this.ruleForm.NotVaccinationStatus = Number(this.ruleForm.NotVaccinationStatus);
                    this.ruleForm.UnknownVaccinationStatus = Number(this.ruleForm.UnknownVaccinationStatus);

                    //this.ruleForm.OverFive = Number(this.ruleForm.OverFive);
                    this.ruleForm.LessFive = Number(this.ruleForm.LessFive);
                    this.ruleForm.LessTen = Number(this.ruleForm.LessTen);
                    this.ruleForm.LessFifteen = Number(this.ruleForm.LessFifteen);
                    this.ruleForm.LessTwenty = Number(this.ruleForm.LessTwenty);
                    this.ruleForm.LessTwentyFive = Number(this.ruleForm.LessTwentyFive);
                    this.ruleForm.LessThirty = Number(this.ruleForm.LessThirty);
                    this.ruleForm.LessFiveThirty = Number(this.ruleForm.LessFiveThirty);
                    this.ruleForm.LessForty = Number(this.ruleForm.LessForty);
                    this.ruleForm.LessFortyFive = Number(this.ruleForm.LessFortyFive);
                    this.ruleForm.OverFifty = Number(this.ruleForm.OverFifty);

                    this.$blockUI.Start();
                    this.$http.EditWeeklyApplications(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetFormS(formName);
                            this.GetInfo();
                            this.EditDilog = false;
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetFormS(formName) {
            this.$refs[formName].resetFields();

            this.ruleForm.CountCases = 0;
            this.ruleForm.Male = 0;
            this.ruleForm.Fmale = 0;
            this.ruleForm.UnknownGender = 0;

            this.ruleForm.Libyan = 0;
            this.ruleForm.Nlibyan = 0;
            this.ruleForm.UnknownNationality = 0;

            this.ruleForm.Deaths = 0;
            this.ruleForm.VaccinationStatus = 0;
            this.ruleForm.NotVaccinationStatus = 0;
            this.ruleForm.UnknownVaccinationStatus = 0;


            //this.ruleForm.OverFive = 0;
            this.ruleForm.LessFive = 0;
            this.ruleForm.LessTen = 0;
            this.ruleForm.LessFifteen = 0;
            this.ruleForm.LessTwenty = 0;
            this.ruleForm.LessTwentyFive = 0;
            this.ruleForm.LessThirty = 0;
            this.ruleForm.LessFiveThirty = 0;
            this.ruleForm.LessForty = 0;
            this.ruleForm.LessFortyFive = 0;
            this.ruleForm.OverFifty = 0;
        },

        Delete(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية الحذف ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteApplicationsWeekly(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },














        FilterByJob() {
            this.Job = [];
            let code = "";
            if (document.getElementById('selectInputPass') != null) {

                if (document.getElementById('selectInputPass').value == null || document.getElementById('selectInputPass').value == '')
                    return;

                code = document.getElementById('selectInputPass').value;
            }
            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetJobsById(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Job = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    //console.error(err);
                });
        },

        FilterByPass1() {
            this.Job = [];
            let code = "";
            if (document.getElementById('selectInputPass1') != null) {

                if (document.getElementById('selectInputPass1').value == null || document.getElementById('selectInputPass1').value == '')
                    return;

                code = document.getElementById('selectInputPass1').value;
            }
            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetJobsById(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Job = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },








        /////////////////////////////////////////////// Helper //////////////////////////////////////////////

        Back() {
            this.state = 0;
            this.SelectedItem = '';
            this.RemoveAllAttachment();
            this.GetInfo(this.pageNo);
        },

        ChangeDate(date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("YYYY-MM-DD");
        },

    },
};
